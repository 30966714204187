<template>
  <div class="container">
    <AssignmentbarSecurity
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    ></AssignmentbarSecurity>
    <div class="animate glow row">
      <h1>Asymmetrische Verschlüsselung</h1>
      <div class="col-6">
        <p>
          Die Idee hinter asymmetrischer Verschlüsselung ist denkbar einfach: es
          werden zwei verschiedene Schlüssel zur Verschlüsselung und
          Entschlüsselung von Nachrichten verwendet. Verschieden bedeutet hierbei,
          dass es nicht leicht möglich ist, von dem Schlüssel zur Verschlüsselung
          auf den Schlüssel für die Entschlüsselung zu schließen.
        </p>
        <p>
          In der asymmetrischen Verschlüsselung erstellt jede:r Teilnehmer:in ein
          solches Schlüsselpaar (engl.: key pair).
        </p>

        <p>
          Der Schlüssel zur Entschlüsselung wird als privater Schlüssel (engl.:
        private key) bezeichnet und bleibt bei der Person, die das Schlüsselpaar
          erstellt hat.
        </p>

        <p>
          Der Schlüssel zur Verschlüsselung wird als öffentlicher Schlüssel
          (engl.: public key) bezeichnet. Dadurch, dass es nicht möglich sein soll,
          von diesem öffentlichen Schlüssel auf den privaten Schlüssel zu
          schließen, kann der öffentliche Schlüssel (wie der Name bereits
          andeutet) mit der Öffentlichkeit geteilt werden - ohne ihn selbst
          verschlüsseln zu müssen.
        </p>
      </div>
      <div class="col-6">
        <img style="width: 100%; height: auto; max-width: 1024px; max-height: 768px;"
          :src="asymmetricEncryption"
          alt="Darstellung asymmetrische Verschlüsselung"
        />
      </div>

      <p>
          In der folgenden Abbildung sehen Sie eine mögliche Schlüsselverteilung mit 
          sowohl öffentlichen als auch privaten Schlüssel. Wichtig ist jedoch, dass in so einer Verteilung jede:r
          Teilnehmer:in immer zumindest sein eigenes Schlüsselpaar besitzen muss, um einerseits
          den öffentlichen Schlüssel teilen zu können, als auch damit verschlüsselte
          Nachrichten wieder entschlüsseln zu können.
          
      </p>

      <div style="display: flex; justify-content: center;">
      <img style="width: 35%; height: auto; max-width: 706px; max-height: 1416px;"
              :src="keyDistribution"
              alt="Verteilung von Schlüsseln unter vier Personen"
      />
      </div>


        <p>
          Während die privaten Schlüsseln
          immer bei der Person bleiben, die das Schlüsselpaar erzeugt hat, können
          die öffentlichen frei verteilt werden.
          Es ist durchaus möglich und auch üblich,
          dass eine Person zur Kommunikation mit verschiedenen Personen den
          gleichen öffentlichen Schlüssel verwendet.
        </p>
    </div>

    <div class="custom-btn" @click="forwardBtnClicked">Weiter</div>
    <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
  </div>
</template>

<script>
import AssignmentbarSecurity from "@/components/inf/security/AssignmentbarSecurity.vue";

export default {
  name: "AsymmetricEncryption",
  components: {
    AssignmentbarSecurity,
  },
  emits: ["asymmetric-encryption-back", "asymmetric-encryption-finished"],
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  data() {
    return {
      asymmetricEncryption: require("@/assets/inf/security/asymmetricEncryption.png"),
      keyDistribution: require("@/assets/inf/security/keyDistribution.jpg"),
    };
  },
  methods: {
    backBtnClicked() {
      this.$emit("asymmetric-encryption-back");
    },
    forwardBtnClicked() {
      this.$emit("asymmetric-encryption-finished");
    },
  },
};
</script>

<style scoped>
.custom-btn,
.custom-btn-secondary {
  float: right;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
