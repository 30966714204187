<template>
  <div class="container">
    <AssignmentbarSecurity
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    ></AssignmentbarSecurity>
    <div class="animate glow row">
      <h1>Nachtrag zu Ersetzung und symmetrischer Verschlüsselung</h1>
      <p>
        Selbst wenn wir im vorangehenden Beispiel nicht bereits einen Teil des 
        Schlüssels bereits gekannt hätten, wäre es mit Hilfe von weiterer 
        Kommunikation, die die gleiche Verschlüsselung verwendet, relativ einfach 
        gewesen, die Verschlüsselung zu knacken - mit Hilfe von einer Statistik 
        darüber, welche Buchstaben in einer Sprache wie oft generell verwendet 
        werden und welche Buchstaben in der Nähe von welchen anderen Buchstaben 
        auftreten.
      </p>


      <div style="display: flex; justify-content: center">
        <img style="width:100%; height: auto; max-width: 589px; max-height: 435px;"
        :src="frequency"
        alt="Verteilung der Buchstaben in der englischen Version von 'Alice in Wonderland'"
        />
      </div>
      <p>
        Alleine ist die zeichenweise Ersetzung keine gute
        Verschlüsselungsmethode. Es gibt jedoch noch einen anderen Grund, warum symmetrische
        Verschlüsselung in der Anwendung oft vermieden wird. Der Grund
        ist, dass der Schlüssel zur Verschlüsselung der Nachricht auch
        kommuniziert werden muss - und da dies vor der Vereinbarung der
        Verschlüsselungsmethode passieren muss, ist diese Kommunikation in der
        Regel unverschlüsselt.
      </p>
      <p>
        Wenn nun jemand diese initiale Kommunikation abfängt, hat sie:er damit
        leichtes Spiel, jede weitere Kommunikation zwischen den beiden Parteien
        zu entschlüsseln. Deswegen werden in der Praxis Verfahren verwendet, deren Schlüssel
        für Verschlüsselung und Entschlüsselung nicht aufeinander rückführbar sind - sogenannte asymmetrische
        Verschlüsselungsverfahren verwendet -, deren praktische Anwendung wir im Folgenden
        kurz skizzieren wollen.
      </p>
    </div>

    <div class="custom-btn" @click="forwardBtnClicked">Weiter</div>
    <!--<div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>-->
  </div>
</template>

<script>
import AssignmentbarSecurity from "@/components/inf/security/AssignmentbarSecurity.vue";

export default {
  name: "AddendumSymmetric",
  components: {
    AssignmentbarSecurity,
  },
  emits: ["addendum-symmetric-back", "addendum-symmetric-finished"],
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  data() {
    return {
      frequency: require("@/assets/inf/security/frequency.png"),
    };
  },
  methods: {
    backBtnClicked() {
      this.$emit("addendum-symmetric-back");
    },
    forwardBtnClicked() {
      this.$emit("addendum-symmetric-finished");
    },
  },
};
</script>

<style scoped>
.custom-btn,
.custom-btn-secondary {
  float: right;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
