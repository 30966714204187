<template>
  <div class="container" v-if="dataReady">
    <AssignmentbarSecurity2
      :showHelpBtn="true"
      :showDismissBtn="true"
    ></AssignmentbarSecurity2>
    <div class="row">
      <h1>Aufgabe: Geheimniskrämer:innen</h1>
      <div class="task-description">
          {{ getCurrentTask() }}
      </div>
      </div>
    <div class="row">
      <div style="display: flex; justify-content: center">
        <div class="key-item">
          <KeylistPerson
            id="keylist-anela"
            ref="anela"
            :personName="'Anela'"
            :keyClicksLocked="!this.lastActionValid || this.currentTask > 4"
            :highlightList="highlightListAnela"
            :highlightKeys="highlightKeysAnela"
            @key-activated="this.assignment2Results.activatedKeys++"
            @key-deactivated="this.assignment2Results.deactivatedKeys++"
            @key-anela-clicked="
              this.currentKeyLists.keyListAnela.keyAnelaActive =
                !this.currentKeyLists.keyListAnela.keyAnelaActive
            "
            @key-beri-clicked="
              this.currentKeyLists.keyListAnela.keyBeriActive =
                !this.currentKeyLists.keyListAnela.keyBeriActive
            "
            @key-cem-clicked="
              this.currentKeyLists.keyListAnela.keyCemActive =
                !this.currentKeyLists.keyListAnela.keyCemActive
            "
            @key-dora-clicked="
              this.currentKeyLists.keyListAnela.keyDoraActive =
                !this.currentKeyLists.keyListAnela.keyDoraActive
            "
          />
        </div>
        <div class="key-item">
          <KeylistPerson
            id="keylist-beri"
            ref="beri"
            :personName="'Beri'"
            :keyClicksLocked="!this.lastActionValid || this.currentTask > 4"
            :highlightList="highlightListBeri"
            :highlightKeys="highlightKeysBeri"
            @key-activated="this.assignment2Results.activatedKeys++"
            @key-deactivated="this.assignment2Results.deactivatedKeys++"
            @key-anela-clicked="
              this.currentKeyLists.keyListBeri.keyAnelaActive =
                !this.currentKeyLists.keyListBeri.keyAnelaActive
            "
            @key-beri-clicked="
              this.currentKeyLists.keyListBeri.keyBeriActive =
                !this.currentKeyLists.keyListBeri.keyBeriActive
            "
            @key-cem-clicked="
              this.currentKeyLists.keyListBeri.keyCemActive =
                !this.currentKeyLists.keyListBeri.keyCemActive
            "
            @key-dora-clicked="
              this.currentKeyLists.keyListBeri.keyDoraActive =
                !this.currentKeyLists.keyListBeri.keyDoraActive
            "
          />
        </div>
        <div class="key-item">
          <KeylistPerson
            id="keylist-cem"
            ref="cem"
            :personName="'Cem'"
            :keyClicksLocked="!this.lastActionValid || this.currentTask > 4"
            :highlightList="highlightListCem"
            :highlightKeys="highlightKeysCem"
            @key-activated="this.assignment2Results.activatedKeys++"
            @key-deactivated="this.assignment2Results.deactivatedKeys++"
            @key-anela-clicked="
              this.currentKeyLists.keyListCem.keyAnelaActive =
                !this.currentKeyLists.keyListCem.keyAnelaActive
            "
            @key-beri-clicked="
              this.currentKeyLists.keyListCem.keyBeriActive =
                !this.currentKeyLists.keyListCem.keyBeriActive
            "
            @key-cem-clicked="
              this.currentKeyLists.keyListCem.keyCemActive =
                !this.currentKeyLists.keyListCem.keyCemActive
            "
            @key-dora-clicked="
              this.currentKeyLists.keyListCem.keyDoraActive =
                !this.currentKeyLists.keyListCem.keyDoraActive
            "
          />
        </div>
        <div class="key-item">
          <KeylistPerson
            id="keylist-dora"
            ref="dora"
            :personName="'Dora'"
            :keyClicksLocked="!this.lastActionValid || this.currentTask > 4"
            :highlightList="highlightListDora"
            :highlightKeys="highlightKeysDora"
            @key-activated="this.assignment2Results.activatedKeys++"
            @key-deactivated="this.assignment2Results.deactivatedKeys++"
            @key-anela-clicked="
              this.currentKeyLists.keyListDora.keyAnelaActive =
                !this.currentKeyLists.keyListDora.keyAnelaActive
            "
            @key-beri-clicked="
              this.currentKeyLists.keyListDora.keyBeriActive =
                !this.currentKeyLists.keyListDora.keyBeriActive
            "
            @key-cem-clicked="
              this.currentKeyLists.keyListDora.keyCemActive =
                !this.currentKeyLists.keyListDora.keyCemActive
            "
            @key-dora-clicked="
              this.currentKeyLists.keyListDora.keyDoraActive =
                !this.currentKeyLists.keyListDora.keyDoraActive
            "
          />
        </div>
      </div>
    </div>
    <a title="teenager icons" class="credit" target="_blank">© Lokatara Studio</a>
    <div class="check-btn-container">
      <div
        class="check-btn"
        @click="checkBtnClicked()"
        :class="
          !this.lastActionValid || this.currentTask > 4
            ? 'check-btn-disabled'
            : ''
        "
      >
        Lösung überprüfen
      </div>
    </div>
    <div class="row">
      <div class="fieldback-field">
      <Assignment2FeedbackField
        :feedbackMessage="this.feedbackMessage"
        :showOkBtn="!this.lastActionValid"
        @ok-clicked="this.resetFeedback()"
      ></Assignment2FeedbackField>
      </div>
    </div>

    <div class="custom-btn" @click="forwardBtnClicked" v-if="this.showContinue">
      Weiter
    </div>
    <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
  </div>
</template>

<script>
import AssignmentbarSecurity2 from "@/components/inf/security/Assignment2/AssignmentbarSecurity2.vue";
import KeylistPerson from "@/components/inf/security/Assignment2/KeylistPerson.vue";
import Assignment2FeedbackField from "@/components/inf/security/Assignment2/Assignment2FeedbackField.vue";
import * as scrolling from "@/helpers/scroll-helper.js";

import store from "@/store.js";

export default {
  name: "SecurityAssignment2",
  components: {
    AssignmentbarSecurity2,
    KeylistPerson,
    Assignment2FeedbackField,
  },
  emits: ["assignment2-back", "assignment2-finished"],
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });

    this.responses = require("@/scripts/security/responses.json");
    this.targetSolutions = require("@/scripts/security/targetSolutions.json");

    this.resetHighlightKeyLists();

    this.assignment2Results.startTime = new Date();

    this.dataReady = true;
  },
  unmounted() {
    console.log(this.assignment2Results.submittedSolutions);
    if (this.assignment2Results.endTime == null) {
      this.assignment2Results.endTime = new Date();
    }

    this.assignment2Results.neededTimeSeconds =
      this.assignment2Results.endTime.getTime() / 1000 -
      this.assignment2Results.startTime.getTime() / 1000;

    this.assignment2Results.totalErrors =
      this.assignment2Results.totalErrorsLevel1 +
      this.assignment2Results.totalErrorsLevel2 +
      this.assignment2Results.totalErrorsLevel3 +
      this.assignment2Results.totalErrorsLevel4 +
      this.assignment2Results.totalErrorsLevel4Repeated;


    store.commit('securityA2Update', this.assignment2Results);
    store.dispatch('securityTotalResultsPost');
  },
  data() {
    return {
      dataReady: false,
      tasks: [
        "Beim Erstellen eines privaten Schlüssels bekommen Ersteller:innen immer ein Schlüsselpaar bestehend aus privaten und öffentlichen Schlüssel. Wie sieht die Verteilung der öffentlichen Schlüssel aus, wenn alle Freund:innen ein solches Schlüsselpaar erstellt haben? Sie können davon ausgehen, dass alle bereits ihren eigenen privaten Schlüssel besitzen.",
        "Anela soll an alle Freund:innen verschlüsselte Nachrichten senden können.",
        "Beri und Cem sollen miteinander sicher kommunizieren können.",
        "Dora soll an Cem Nachrichten schicken können.",
        "Beri möchte verschlüsselte Nachrichten verschicken können, die nur Dora lesen darf.",
      ],
      lastActionValid: true,
      currentTask: 0,
      consecutiveErrors: 0,
      showContinue: false,
      responses: {},
      targetSolutions: {},
      feedbackMessage: "",
      highlightListAnela: false,
      highlightListBeri: false,
      highlightListCem: false,
      highlightListDora: false,
      highlightKeysAnela: {
        keyAnelaColor: "",
        keyBeriColor: "",
        keyCemColor: "",
        keyDoraColor: "",
      },
      highlightKeysBeri: {
        keyAnelaColor: "",
        keyBeriColor: "",
        keyCemColor: "",
        keyDoraColor: "",
      },
      highlightKeysCem: {
        keyAnelaColor: "",
        keyBeriColor: "",
        keyCemColor: "",
        keyDoraColor: "",
      },
      highlightKeysDora: {
        keyAnelaColor: "",
        keyBeriColor: "",
        keyCemColor: "",
        keyDoraColor: "",
      },
      currentKeyLists: {
        keyListAnela: {
          keyAnelaActive: false,
          keyBeriActive: false,
          keyCemActive: false,
          keyDoraActive: false,
        },
        keyListBeri: {
          keyAnelaActive: false,
          keyBeriActive: false,
          keyCemActive: false,
          keyDoraActive: false,
        },
        keyListCem: {
          keyAnelaActive: false,
          keyBeriActive: false,
          keyCemActive: false,
          keyDoraActive: false,
        },
        keyListDora: {
          keyAnelaActive: false,
          keyBeriActive: false,
          keyCemActive: false,
          keyDoraActive: false,
        },
      },
      //logging parameters
      assignment2Results: {
        assignment2Finished: false,
        startTime: null, 
        endTime: null, 
        neededTimeSeconds: 0, 
        activatedKeys: 0, 
        deactivatedKeys: 0, 
        totalErrors: 0, 
        totalErrorsLevel1: 0, 
        totalErrorsLevel2: 0, 
        totalErrorsLevel3: 0,
        totalErrorsLevel4: 0, 
        totalErrorsLevel4Repeated: 0,
        submittedSolutions: [], 
        // {
        //   taskValid: true or false
        //   taskNumber: 1-4
        //   submitTime: dateTime
        //   targetSolution: {}
        //   userSolution: {}
        //   comment: null
        // }
      },
    };
  },
  methods: {
    backBtnClicked() {
      this.$emit("assignment2-back");
    },
    forwardBtnClicked() {
     
      this.$emit("assignment2-finished");
      
    },
    addSubmittedSolutionToResults(userSolution, targetSolution) {
      let submittedSolution = {};
      submittedSolution.solutionValid = this.checkTargetSolution(targetSolution);
      submittedSolution.taskNumber = this.currentTask;
      submittedSolution.submitTime = new Date();
      submittedSolution.targetSolution = targetSolution;
      submittedSolution.userSolution = userSolution;

      this.assignment2Results.submittedSolutions.push(JSON.parse(JSON.stringify(submittedSolution)));
    },
    checkBtnClicked() {

      //check button is locked or all tasks are completed 
      if (!this.lastActionValid || this.currentTask > 4) {
        return;
      }

      switch (this.currentTask) {
        case 0:
          this.lastActionValid = this.checkTask0();      
          this.addSubmittedSolutionToResults(
        this.currentKeyLists,
        this.targetSolutions.task0
      );
          console.log(this.assignment2Results.submittedSolutions);
          if (this.lastActionValid) {
            this.handleCorrectSolution();
            this.feedbackMessage = this.responses.validResponses.response0;
          } else {
            this.setErrorMessage(this.targetSolutions.task0);
          }
          break;
        case 1:
          this.lastActionValid = this.checkTask1();
          this.addSubmittedSolutionToResults(
            this.currentKeyLists,
            this.targetSolutions.task1
          );
          console.log(this.assignment2Results.submittedSolutions);
          if (this.lastActionValid) {
            this.handleCorrectSolution();
            this.feedbackMessage = this.responses.validResponses.response1;
          } else {
            this.setErrorMessage(this.targetSolutions.task1);
          }
          break;
        case 2:
          this.lastActionValid = this.checkTask2();
          this.addSubmittedSolutionToResults(
        this.currentKeyLists,
        this.targetSolutions.task2
      );
          console.log(this.assignment2Results.submittedSolutions);
          if (this.lastActionValid) {
            this.handleCorrectSolution();
            this.feedbackMessage = this.responses.validResponses.response2;
          } else {
            this.setErrorMessage(this.targetSolutions.task2);
          }
          break;
        case 3:
          this.lastActionValid = this.checkTask3();
          this.addSubmittedSolutionToResults(
        this.currentKeyLists,
        this.targetSolutions.task3
      );
          console.log(this.assignment2Results.submittedSolutions);
          if (this.lastActionValid) {
            this.handleCorrectSolution();
            this.feedbackMessage = this.responses.validResponses.response3;
          } else {
            this.setErrorMessage(this.targetSolutions.task3);
          }
          break;
        case 4:
          this.lastActionValid = this.checkTask4();
          this.addSubmittedSolutionToResults(
        this.currentKeyLists,
        this.targetSolutions.task4
      );
          console.log(this.assignment2Results.submittedSolutions);
          if (this.lastActionValid) {
            this.handleCorrectSolution();
            this.feedbackMessage = this.responses.validResponses.response4;
            this.showContinue = true;
            this.assignment2Results.assignment2Finished = true;
          } else {
            this.setErrorMessage(this.targetSolutions.task4);
            //this.highlightWrongKeyLists();
            //this.showSolution()
          }
          break;
        default:
          throw new Error("current task not valid");
      }
      scrolling.scrollToElementByClassName("feedback-field")
    },
    handleCorrectSolution() {
      this.consecutiveErrors = 0;
      this.currentTask++;
      this.$refs.anela.lockActiveKeys();
      this.$refs.beri.lockActiveKeys();
      this.$refs.cem.lockActiveKeys();
      this.$refs.dora.lockActiveKeys();
    },
    setErrorMessage(targetSolution) {
      this.consecutiveErrors++; //user made an error
      if (this.consecutiveErrors == 1) {
        this.assignment2Results.totalErrorsLevel1++;
        this.feedbackMessage = this.responses.invalidResponses.level1;
      } else if (this.consecutiveErrors == 2) {
        this.assignment2Results.totalErrorsLevel2++;
        this.feedbackMessage = this.responses.invalidResponses.level2;
      } else if (this.consecutiveErrors == 3) {
        this.assignment2Results.totalErrorsLevel3++;
        this.feedbackMessage = this.responses.invalidResponses.level3;
        this.highlightWrongKeyLists(targetSolution);
      } else {
        if (this.consecutiveErrors == 4) {
          this.assignment2Results.totalErrorsLevel4++;
        } else {
          this.assignment2Results.totalErrorsLevel4Repeated++;
        }
        this.feedbackMessage = this.responses.invalidResponses.level4;
        this.highlightWrongKeyLists(targetSolution);
        this.showSolution(targetSolution);
      }
    },
    highlightWrongKeyLists(targetSolution) {
      if (!this.isAnelaListCorrect(targetSolution)) {
        this.highlightListAnela = true;
      }
      if (!this.isBeriListCorrect(targetSolution)) {
        this.highlightListBeri = true;
      }
      if (!this.isCemListCorrect(targetSolution)) {
        this.highlightListCem = true;
      }
      if (!this.isDoraListCorrect(targetSolution)) {
        this.highlightListDora = true;
      }
    },
    showSolution(targetSolution) {
      if (!this.isAnelaListCorrect(targetSolution)) {
        //highlight keys in anela list
        this.highlightKeysAnela.keyAnelaColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListAnela.keyAnelaActive,
          targetSolution.keyListAnela.keyAnelaActive
        );
        this.highlightKeysAnela.keyBeriColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListAnela.keyBeriActive,
          targetSolution.keyListAnela.keyBeriActive
        );
        this.highlightKeysAnela.keyCemColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListAnela.keyCemActive,
          targetSolution.keyListAnela.keyCemActive
        );
        this.highlightKeysAnela.keyDoraColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListAnela.keyDoraActive,
          targetSolution.keyListAnela.keyDoraActive
        );
      }
      if (!this.isBeriListCorrect(targetSolution)) {
        //highlight keys in beri list
        this.highlightKeysBeri.keyAnelaColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListBeri.keyAnelaActive,
          targetSolution.keyListBeri.keyAnelaActive
        );
        this.highlightKeysBeri.keyBeriColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListBeri.keyBeriActive,
          targetSolution.keyListBeri.keyBeriActive
        );
        this.highlightKeysBeri.keyCemColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListBeri.keyCemActive,
          targetSolution.keyListBeri.keyCemActive
        );
        this.highlightKeysBeri.keyDoraColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListBeri.keyDoraActive,
          targetSolution.keyListBeri.keyDoraActive
        );
      }
      if (!this.isCemListCorrect(targetSolution)) {
        //highlight keys in beri list
        this.highlightKeysCem.keyAnelaColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListCem.keyAnelaActive,
          targetSolution.keyListCem.keyAnelaActive
        );
        this.highlightKeysCem.keyBeriColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListCem.keyBeriActive,
          targetSolution.keyListCem.keyBeriActive
        );
        this.highlightKeysCem.keyCemColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListCem.keyCemActive,
          targetSolution.keyListCem.keyCemActive
        );
        this.highlightKeysCem.keyDoraColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListCem.keyDoraActive,
          targetSolution.keyListCem.keyDoraActive
        );
      }
      if (!this.isDoraListCorrect(targetSolution)) {
        //highlight keys in dora list
        this.highlightKeysDora.keyAnelaColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListDora.keyAnelaActive,
          targetSolution.keyListDora.keyAnelaActive
        );
        this.highlightKeysDora.keyBeriColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListDora.keyBeriActive,
          targetSolution.keyListDora.keyBeriActive
        );
        this.highlightKeysDora.keyCemColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListDora.keyCemActive,
          targetSolution.keyListDora.keyCemActive
        );
        this.highlightKeysDora.keyDoraColor = this.getKeyHighlightColor(
          this.currentKeyLists.keyListDora.keyDoraActive,
          targetSolution.keyListDora.keyDoraActive
        );
      }
    },
    getKeyHighlightColor(userKey, targetKey) {
      if (targetKey) {
        if (!userKey) {
          return "green";
        }
      } else if (!targetKey) {
        if (userKey) {
          return "red";
        }
      }
    },
    isAnelaListCorrect(targetSolution) {
      return (
        JSON.stringify(this.currentKeyLists.keyListAnela) ==
        JSON.stringify(targetSolution.keyListAnela)
      );
    },
    isBeriListCorrect(targetSolution) {
      return (
        JSON.stringify(this.currentKeyLists.keyListBeri) ==
        JSON.stringify(targetSolution.keyListBeri)
      );
    },
    isCemListCorrect(targetSolution) {
      return (
        JSON.stringify(this.currentKeyLists.keyListCem) ==
        JSON.stringify(targetSolution.keyListCem)
      );
    },
    isDoraListCorrect(targetSolution) {
      return (
        JSON.stringify(this.currentKeyLists.keyListDora) ==
        JSON.stringify(targetSolution.keyListDora)
      );
    },
    resetFeedback() {
      this.lastActionValid = true;
      this.feedbackMessage = "";
      this.resetHighlightKeyLists();
      this.resetHighlightKeys();
    },
    resetHighlightKeyLists() {
      this.highlightListAnela = false;
      this.highlightListBeri = false;
      this.highlightListCem = false;
      this.highlightListDora = false;
    },
    resetHighlightKeys() {
      this.highlightKeysAnela = {
        keyAnelaColor: "",
        keyBeriColor: "",
        keyCemColor: "",
        keyDoraColor: "",
      };
      this.highlightKeysBeri = {
        keyAnelaColor: "",
        keyBeriColor: "",
        keyCemColor: "",
        keyDoraColor: "",
      };
      this.highlightKeysCem = {
        keyAnelaColor: "",
        keyBeriColor: "",
        keyCemColor: "",
        keyDoraColor: "",
      };
      this.highlightKeysDora = {
        keyAnelaColor: "",
        keyBeriColor: "",
        keyCemColor: "",
        keyDoraColor: "",
      };
    },
    checkTask0() {
      const targetSolution = this.targetSolutions.task0;
      return this.checkTargetSolution(targetSolution);
    },
    checkTask1() {
      const targetSolution = this.targetSolutions.task1;
      return this.checkTargetSolution(targetSolution);
    },
    checkTask2() {
      const targetSolution = this.targetSolutions.task2;
      return this.checkTargetSolution(targetSolution);
    },
    checkTask3() {
      const targetSolution = this.targetSolutions.task3;
      return this.checkTargetSolution(targetSolution);
    },
    checkTask4() {
      const targetSolution = this.targetSolutions.task4;
      return this.checkTargetSolution(targetSolution);
    },
    checkTargetSolution(targetSolution) {
      if (
        this.isAnelaListCorrect(targetSolution) &&
        this.isBeriListCorrect(targetSolution) &&
        this.isCemListCorrect(targetSolution) &&
        this.isDoraListCorrect(targetSolution)
      ) {
        return true;
      } else {
        return false;
      }
    },
    getCurrentTask() {
      return this.tasks[this.currentTask];
    },
  },
};
</script>

<style scoped>
.custom-btn,
.custom-btn-secondary {
  float: right;
}
.task-description {
  font-size: 1.2em;
  font-weight: bold;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-btn-container {
  margin: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.check-btn {
  padding: 10px;
  font-size: 1.2em;
  height: 50px;
  min-width: 20ch;
  font-weight: bolder;
  background-color: #ece1e1;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid black;
}

.check-btn:hover {
  background-color: #ecd5d5;
  border: 2px solid black;
}

.check-btn-disabled {
  background-color: lightgray;
  cursor: default;
  color: darkgrey;
}
.check-btn-disabled:hover {
  background-color: lightgray;
  cursor: default;
  color: darkgrey;
}

.key-item {
  margin: 0px 30px 0px 30px;
}
.credit {
  font-size: 10px; /* or even smaller if you want */
  color: #555; /* Optional: change the color if you like */
  text-align:end; /* Optional: centers the link */
  display: block; /* Optional: places the link on its own line */
  margin-top: 10px; /* Optional: adds space above the link */
  padding-right: 170px;
}
</style>
