<template>
  <div
    class="keylist-container"
    :class="highlightList ? 'keylist-container-error' : ''"
  >
    <div class="keylist-person">
      <div class="person-avatar">
        <img class="" :src="getAvatar()" alt="Avatar Person" />
      </div>
      <div
        class="person-name"
      >
        {{ personName }}
      </div>
    </div>
    <div class="keylist-keys">
      <div class="private-key-container">
        <div class="private-keys-title">Private Schlüssel</div>
        <KeyIcon
          id="key-private"
          class="key"
          :class="true"
          :color="'gray'"
          :keyName="this.personName.charAt(0).toUpperCase()"
          :errorMessage="''"
        />
      </div>
      <div class="public-keys-container">
        <div class="keylist-title">Öffentliche Schlüssel</div>
        <KeyIcon
          id="key-anela"
          class="key"
          :class="amILocked('anela') ? 'key-locked' : ''"
          :color="getAnelaKeyColor()"
          :keyName="'A'"
          :errorMessage="getAnelaErrorMessage()"
          @click="toggleAnelaActive()"
        />
        <KeyIcon
          id="key-beri"
          class="key"
          :class="amILocked('beri') ? 'key-locked' : ''"
          :color="getBeriKeyColor()"
          :keyName="'B'"
          :errorMessage="getBeriErrorMessage()"
          @click="toggleBeriActive()"
        />
        <KeyIcon
          id="key-cem"
          class="key"
          :class="amILocked('cem') ? 'key-locked' : ''"
          :color="getCemKeyColor()"
          :keyName="'C'"
          :errorMessage="getCemErrorMessage()"
          @click="toggleCemActive()"
        />
        <KeyIcon
          id="key-dora"
          class="key"
          :class="amILocked('dora') ? 'key-locked' : ''"
          :color="getDoraKeyColor()"
          :keyName="'D'"
          :errorMessage="getDoraErrorMessage()"
          @click="toggleDoraActive()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import KeyIcon from "@/components/icons/KeyIcon.vue";

export default {
  name: "KeylistPerson",
  components: {
    KeyIcon,
  },
  emits: [
    "key-anela-clicked",
    "key-beri-clicked",
    "key-cem-clicked",
    "key-dora-clicked",
    "key-activated",
    "key-deactivated",
  ],
  props: {
    personName: { type: String, required: true },
    keyClicksLocked: { type: Boolean, required: false, default: false },
    highlightList: { type: Boolean, required: false, default: false },
    highlightKeys: {
      type: Object,
      required: false,
      default: () => ({
        keyAnelaColor: "",
        keyBeriColor: "",
        keyCemColor: "",
        keyDoraColor: "",
      }),
    },
  },
  mounted() {},
  data() {
    return {
      keyAnelaActive: false,
      keyBeriActive: false,
      keyCemActive: false,
      keyDoraActive: false,
      isAnelaLocked: false,
      isBeriLocked: false,
      isCemLocked: false,
      isDoraLocked: false
    };
  },
  methods: {
    getAvatar() {
      let avatar;
      switch (this.personName) {
        case "Anela":
          avatar = require("@/assets/inf/security/anela.svg");
          break;
        case "Beri":
          avatar = require("@/assets/inf/security/beri.svg");
          break;
        case "Cem":
          avatar = require("@/assets/inf/security/cem.svg");
          break;
        case "Dora":
          avatar = require("@/assets/inf/security/dora.svg");
          break;
        default:
          new Error("Avatar for " + this.personName + " not found");
      }
      return avatar;
    },
    toggleAnelaActive() {
      if(this.keyClicksLocked || this.isAnelaLocked){
        return;
      }
        this.$emit("key-anela-clicked");
        if(!this.keyAnelaActive){
          this.$emit("key-activated");
        }
        else if(this.keyAnelaActive){
          this.$emit("key-deactivated");
        }
        this.keyAnelaActive = !this.keyAnelaActive;
    },
    toggleBeriActive() {
      if (this.keyClicksLocked || this.isBeriLocked) {
        return;
      }
        this.$emit("key-beri-clicked");
        if(!this.keyBeriActive){
          this.$emit("key-activated");
        }
        else if(this.keyBeriActive){
          this.$emit("key-deactivated");
        }
        this.keyBeriActive = !this.keyBeriActive;
      
    },
    toggleCemActive() {
      if (this.keyClicksLocked || this.isCemLocked) {
        return;
      }
        this.$emit("key-cem-clicked");
        if(!this.keyCemActive){
          this.$emit("key-activated");
        }
        else if(this.keyCemActive){
          this.$emit("key-deactivated");
        }
        this.keyCemActive = !this.keyCemActive;
      
    },
    toggleDoraActive() {
      if (this.keyClicksLocked || this.isDoraLocked) {
        return;
      }
        this.$emit("key-dora-clicked");
        if(!this.keyDoraActive){
          this.$emit("key-activated");
        }
        else if(this.keyDoraActive){
          this.$emit("key-deactivated");
        }
        this.keyDoraActive = !this.keyDoraActive;
      
    },
    getAnelaKeyColor() {
      if (this.highlightKeys.keyAnelaColor != "") {
        if (this.highlightKeys.keyAnelaColor === "red") {
          return "red";
        } else if (this.highlightKeys.keyAnelaColor === "green") {
          return "green";
        }
      }

      if (this.keyAnelaActive) {
        return "yellow";
      } else {
        return "white";
      }
    },
    getBeriKeyColor() {
      if (this.highlightKeys.keyBeriColor != "") {
        if (this.highlightKeys.keyBeriColor === "red") {
          return "red";
        } else if (this.highlightKeys.keyBeriColor === "green") {
          return "green";
        }
      }

      if (this.keyBeriActive) {
        return "yellow";
      } else {
        return "white";
      }
    },
    getCemKeyColor() {
      if (this.highlightKeys.keyCemColor != "") {
        if (this.highlightKeys.keyCemColor === "red") {
          return "red";
        } else if (this.highlightKeys.keyCemColor === "green") {
          return "green";
        }
      }

      if (this.keyCemActive) {
        return "yellow";
      } else {
        return "white";
      }
    },
    getDoraKeyColor() {
      if (this.highlightKeys.keyDoraColor != "") {
        if (this.highlightKeys.keyDoraColor === "red") {
          return "red";
        } else if (this.highlightKeys.keyDoraColor === "green") {
          return "green";
        }
      }

      if (this.keyDoraActive) {
        return "yellow";
      } else {
        return "white";
      }
    },

    getAnelaErrorMessage() {
      if (this.highlightKeys.keyAnelaColor != "") {
        if (this.highlightKeys.keyAnelaColor === "red") {
          return "Falsch";
        } else if (this.highlightKeys.keyAnelaColor === "green") {
          return "Fehlt";
        }
      }
      return "";
    },
    getBeriErrorMessage() {
      if (this.highlightKeys.keyBeriColor != "") {
        if (this.highlightKeys.keyBeriColor === "red") {
          return "Falsch";
        } else if (this.highlightKeys.keyBeriColor === "green") {
          return "Fehlt";
        }
      }
      return " ";
    },
    getCemErrorMessage() {
      if (this.highlightKeys.keyCemColor != "") {
        if (this.highlightKeys.keyCemColor === "red") {
          return "Falsch";
        } else if (this.highlightKeys.keyCemColor === "green") {
          return "Fehlt";
        }
      }
      return " ";
    },
    getDoraErrorMessage() {
      if (this.highlightKeys.keyDoraColor != "") {
        if (this.highlightKeys.keyDoraColor === "red") {
          return "Falsch";
        } else if (this.highlightKeys.keyDoraColor === "green") {
          return "Fehlt";
        }
      }
      return " ";
    },
    lockActiveKeys(){
      if(this.keyAnelaActive){
        console.log('set keyAnelaLocked')
        this.isAnelaLocked = true;
      }
      if(this.keyBeriActive){
        this.isBeriLocked = true;
      }
      if(this.keyCemActive){
        this.isCemLocked = true;
      }
      if(this.keyDoraActive){
        this.isDoraLocked = true;
      }
    },
    amILocked(requestor){
      if(this.keyClicksLocked){
        return true;
      }
      if(requestor === 'anela'){
        return this.isAnelaLocked;
      }
      if(requestor === 'beri'){
        return this.isBeriLocked;
      }
      if(requestor === 'cem'){
        return this.isCemLocked;
      }
      if(requestor === 'dora'){
        return this.isDoraLocked;
      }
    }
  },
};
</script>

<style scoped>

.person-name{  
  display: flex;
          justify-content: center;
          font-size: 1.3em;
          margin-bottom: 10px;
}
.key {
  cursor: pointer;
  width: 120px;
  margin: 5px;
}
.key-locked {
  cursor: default;
}

/*
#key-anela {
  border-bottom: 1px solid black;
}

#key-beri {
  border-bottom: 1px solid black;
}

#key-cem {
  border-bottom: 1px solid black;
}
*/
.key:hover {
  box-shadow: 1em;
}

.keylist-container {
  border: 3px solid black;
  background-color: rgb(240, 240, 240);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.keylist-container-error {
  border: 5px solid red;
}

.person-avatar {
  width: 120px;
}

.keylist-keys {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px;
  background-color: #ece1e1;
  padding: 10px;
}

.private-key-container{
  border-bottom: 2px solid black;
  margin-bottom: 10px;
}

#key-private:hover{
  cursor: default;
}

.public-keys-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
