<template>
  <div class="">
    <AssignmentbarSecurity
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    ></AssignmentbarSecurity>
    <div class="animate glow row">
    <h1>Tutorial: Geheimniskrämer:innen</h1>
    
      <p>
        Anela, Beri, Cem und Dora sind Freund:innen, haben heiße News miteinander
        zu teilen. So heiß, dass nicht alle vier Freund:innen jede Neuigkeit
        kennen soll. Um dabei auf Nummer sicher zu gehen, wollen sie ihre
        Kommunikation asymmetrisch verschlüsseln.
      </p>
      <p>
        In der folgenden Aufgabe können Sie annehmen, dass jede der vier
        Personen ihren zugehörigen privaten Schlüssel bereits besitzt.
        <strong>Es werden also nur öffentliche Schlüssel verteilt.</strong>
      </p>
      </div>
      <div class="animate glow row">
        <div class="col-8" style="display: flex; flex-direction: column; justify-content: center;">
          <div class="column-text">
          <p>
            Der öffentliche Schlüssel einer Person wird immer mit dem Anfangsbuchstaben der
            Person gekennzeichnet. Anela, Beri, Cem und Dora können öffentliche Schlüssel von den anderen
          Personen erhalten. In der folgenden Aufgabe sollen Sie den Freund:innen öffentliche Schlüssel von anderen Person zuweisen, indem Sie diese in deren
            Schlüsselliste aktivieren oder deaktivieren.
          </p>
        <p>
          <strong
            >Öffentliche Schlüssel können Sie vergeben und auch wieder entfernen, indem Sie diese in der Schlüsselliste einer Person
            anklicken.
          Nebenan sehen Sie, zum Beispiel, Anelas Schlüsselliste. Versuchen Sie, Anela den öffentlichen Schlüssel von Cem zuzuweisen. 
          Zum Aktivieren der öffentlichen 
Schlüsseln sollten Sie auch auf diese Schlüssel klicken.</strong
          >
        </p>
      </div>
        </div>
      <div class="col-4">
        <KeylistPerson id="keylist-anela" :personName="'Anela'" @key-cem-clicked="this.tutorialFinished = true" />
      </div>
      <a title="teenager icons" class="credit" target="_blank">© Lokatara Studio</a>
       

    </div>

    <div class="animate glow row">
      <h2>Ihre Aufgabe:</h2>
      <p>
        Die vier Freund:innen haben Sie darum gebeten, die öffentlichen
        Schlüssel nach einigen Vorgaben zu verteilen. Ihre Aufgabe ist, die
        Vorgaben der Reihe nach zu erfüllen, damit alle Freund:innen mit der
        Sicherheit der Kommunikation glücklich sind.
        <strong
          >Aber Achtung: Das Verteilen und Verwalten von öffentlichen Schlüsseln
          ist sehr aufwändig. Versuchen Sie daher stets eine
          minimale Verteilung der öffentlichen Schlüssel zu modellieren.</strong
        >
        Wenn Sie denken, dass Sie eine Aufgabe korrekt bearbeitet haben, klicken
        Sie auf den Button, um Ihre Lösung zu überprüfen.
      </p>
    </div>
    <div class="row" v-if="this.showErrorMessage" style="color: red; font-size: larger; display: flex; justify-content: center;">
      Aktivieren Sie zuerst den öffentlichen Schlüssel von Cem in Anelas Schlüsselliste um fortzufahren.
    </div>

    <div class="custom-btn" @click="forwardBtnClicked">Aufgabe starten</div>
    <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
  </div>
</template>

<script>
import AssignmentbarSecurity from "@/components/inf/security/AssignmentbarSecurity.vue";
//import KeyIcon from "@/components/icons/KeyIcon.vue";
import KeylistPerson from "@/components/inf/security/Assignment2/KeylistPerson.vue";

export default {
  name: "SecurityAssignment2Tutorial",
  components: {
    AssignmentbarSecurity,
    //KeyIcon,
    KeylistPerson,
  },
  emits: ["assignment2-tutorial-back", "assignment2-tutorial-finished"],
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  data() {
    return {
      keyActive: false,
      showErrorMessage: false,
      tutorialFinished: false
    };
  },
  methods: {
    toggleKeyActive() {
      this.keyActive = !this.keyActive;
    },
    backBtnClicked() {
      this.$emit("assignment2-tutorial-back");
    },
    forwardBtnClicked() {
      if(!this.tutorialFinished){
        this.showErrorMessage = true;
        return;
      }
      this.$emit("assignment2-tutorial-finished");
    },
  },
};
</script>

<style scoped>
p{
  line-height: 1.3;
}
.custom-btn,
.custom-btn-secondary {
  float: right;
}

#key-anela {
  cursor: pointer;
  flex: 1;
}

#keylist-anela {
  margin: 20px;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
.credit {
  font-size: 10px; /* or even smaller if you want */
  color: #555; /* Optional: change the color if you like */
  text-align:end; /* Optional: centers the link */
  display: block; /* Optional: places the link on its own line */
  margin-top: 10px; /* Optional: adds space above the link */
  padding-right: 170px;
}
</style>
