<template>
  <div class="container">
    <AssignmentbarSecurity
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    ></AssignmentbarSecurity>
    <div class="animate glow row">
        <h1>Pretty Good Privacy (PGP)</h1>
        <p>
          Sollte Sie interessieren, wie asymmetrische Verschlüsselung in der Praxis aussehen kann, 
          empfehlen wir Ihnen, Pretty Good Privacy (kurz: PGP) auszuprobieren. 
        </p>

        <p>
          Mit PGP können Sie Schlüsselpaare erstellen und damit Texte ver- und entschlüsseln.
          Zur Berechnung der Schlüssel wird eine Variante des bekannten RSA-Algorithmus verwendet. 
          PGP erlaubt uns außerdem, andere mit asymmetrischer Verschlüsselung eng verwandte Probleme zu lösen, wie z. B. 
          Nachrichten zu zertifizieren, also die Authenzität einer Nachricht zu überprüfen. 
        </p>
        <p>
          Das folgende Bild zeigt eine mit PGP verschlüsselte Nachricht <em>„Hallo Welt!“</em>.        
        </p>
        <div style="display: flex; justify-content: center">
          <img style="width:100%; height: auto; max-width: 738px; max-height: 333px;"
          :src="pgp"
          alt = "Die Nachricht'Hello World' mit PGP verschlüsselt."
          />
        </div>

        <p>
          Eine bekannte graphische Oberfläche für OpenPGP ist <a href="https://www.openpgp.org/software/kleopatra/" target="_blank" rel="noopener noreferrer">Kleopatra</a>.
        </p>   
    </div>

    <div class="custom-btn" @click="forwardBtnClicked">Weiter</div>
</div>
</template>

<script>
import AssignmentbarSecurity from "@/components/inf/security/AssignmentbarSecurity.vue";

export default {
  name: "PrettyGoodPrivacy",
  components: {
    AssignmentbarSecurity,
  },
  emits: ["pgp-back","pgp-finished"],
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  data() {
    return {
      pgp: require("@/assets/inf/security/pgp.png"),
    };
  },
  methods: {
    backBtnClicked() {
        this.$emit("pgp-back");
    },
    forwardBtnClicked() {
        this.$emit("pgp-finished");
    }
  },
};
</script>

<style scoped>
.custom-btn,
.custom-btn-secondary {
  float: right;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
