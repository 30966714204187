<template>
  <div class="container">
    <AssignmentbarSecurity
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    ></AssignmentbarSecurity>
    <div class="details-container" v-if="!showFinishSortingContainer">
    <div class="animate glow row">
      <h1>Weitere Details: Security im Allgemeinen</h1>
      <p>
        Während sich Kryptographie mit Aspekten von sicherer Kommunikation beschäftigt, 
        beschäftigen sich auch andere Gebiete der Informatik mit Sicherheit. Dies kann
        von unauthorisierten Datenbankzugriffen, wie es bei SQL Injections der Fall ist,
        aber auch Softwarelücken wie beim berühmten Heartbleed Bug, als auch auf Lücken
        auf Hardware-Ebene, wie es beim Specter Bug der Fall war.
      </p>

      <p>
        Kryptographie selbst hat außerdem viele Schnittstellen mit Mathematik, als auch mit
        theoretischer Informatik. Zum einen bauen praxisrelevante Algorithmen, wie der RSA-Algorithmus auf
        mathematischen Eigenschaften von Primzahlzerlegungen, als auch auf die Schwierigkeit, solche 
        Zerlegungen bisher nicht schnell berechnen zu können.
      </p>

    </div>
    <div class="custom-btn" @click="showFinishSortingContainerClicked()">
      Weiter
    </div>
    <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
  </div> 
    <div class="animate glow finish-sorting-container" v-if="showFinishSortingContainer">
      <h1>Herzlichen Glückwunsch!</h1>
      Sie haben „Mit Sicherheit“ nun erfolgreich abgeschlossen. In diesem Modul
      haben Sie kennengelernt, wie sich die Verschlüsselung mittels
      symmetrischer und asymmetrischer Verfahren funktioniert. Dafür haben Sie
      im ersten Teil eine einfache unsicher verschlüsselte Nachricht wieder
      lesbar gemacht. Im zweiten Teil haben Sie einen computergestützten Ansatz
      in der Kryptographie gesehen. Hier haben Sie den Grundgedanken hinter
      asymmetrischer Verschlüsselung kennengelernt.

      <div class="user-study-comment">
        Im Rahmen der User Study haben wir einen kurzen Fragebogen für dieses
        Modul vorbereitet.
        <strong
          >Bitte öffnen und beantworten Sie diesen, bevor Sie mit anderen Teilen
          des OSA fortfahren.
        </strong>
        Nur eine Bearbeitung unmittelbar nach Durchführung des Moduls erlaubt es
        unverfälschte Antworten zu erfassen.<br/>

        Beantworten Sie diesen Fragebogen nur beim ersten Abschluss des Experimentiermoduls „Mit Sicherheit".  
        Sollten Sie diesen Fragebogen bereits einmal bearbeitet haben, können Sie jetzt zum OSA zurückkehren.
        <div class="" style="display: flex; justify-content: center">
          <a href="https://survey.media.tuwien.ac.at/index.php/835736?lang=de" target="_blank"
            ><div class="custom-btn" @click="this.questionnaireOpened = true">
              Zum Fragebogen
            </div></a
          >
        </div>
      </div>

      <div class="btn-container-right">
        <div
          class="custom-btn"
          @click="finishConclusion()"
        >
          Modul abschließen
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignmentbarSecurity from "@/components/inf/security/AssignmentbarSecurity.vue";

import * as scrolling from "@/helpers/scroll-helper.js";

export default {
  name: "SecurityConclusion",
  components: {
    AssignmentbarSecurity,
  },
  emits: ["security-conclusion-back", "security-conclusion-finished"],
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  data() {
    return {
      showFinishSortingContainer: false,
      questionnaireOpened: false,
    };
  },
  methods: {
    async showFinishSortingContainerClicked() {
      this.showFinishSortingContainer = true;
      await this.$nextTick();
      setTimeout(function () {
        scrolling.scrollToElementByClassName("finish-sorting-container", {
          behavior: "smooth",
        });
      }, 80);
    },
    finishConclusion() {
      //if (this.questionnaireOpened) {
        this.$emit("security-conclusion-finished");
      //}
    },
    backBtnClicked() {
      this.$emit("security-conclusion-back");
    },
    forwardBtnClicked() {
      this.$emit("security-conclusion-finished");
    },
  },
};
</script>

<style scoped>
.custom-btn,
.custom-btn-secondary {
  float: right;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
