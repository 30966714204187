<template>
  <div class="">
    <p class="sentence" v-if="isFinished">
      <span v-for="(word, index) in text.sentence" class="word" :key="index">
        <span v-for="(letter, index) in word" class="letter" :key="index">
          {{ text.letters[letter].encoded }}
        </span>
      </span>
    </p>
    <p class="sentence">
      <span v-for="(word, index) in text.sentence" class="word" :key="index">
        <template v-for="(letter, index) in word" :key="index">
          <span v-if="text.letters[letter].isDecoded" class="letter decoded">
            {{ letter }}
          </span>
          <span
            v-else
            @mouseenter="hover = letter"
            @mouseleave="hover = false"
            @click="focusOn(text.letters[letter].encoded)"
            class="letter"
            :class="{
              encoded: !text.letters[letter].isDecoded,
              [text.letters[letter].encoded]: true,
              'letter-hover': letter === hover,
              'letter-focus': letter === focus,
              replaced: text.letters[letter].replacement,
            }"
          >
            {{
              text.letters[letter].replacement
                ? text.letters[letter].replacement
                : text.letters[letter].encoded
            }}
          </span>
        </template>
      </span>
    </p>
    <p v-if="!isFinished">
      Durch Eingabe in den Feldern unten, können Sie die einzelnen Zeichen im
      obigen Text durch ein anderes Zeichen ersetzen lassen.
    </p>
    <p class="letter-forms" v-if="!isFinished">
      <template v-for="(letter, index) in text.letters" :key="index">
        <template v-if="letter.encoded">
          <input
            :id="letter.encoded"
            class="input-letter"
            :class="{ replaced: letter.replacement }"
            type="text"
            :placeholder="letter.encoded"
            :disabled="letter.isDecoded"
            maxlength="1"
            :value="letter.replacement"
            @input="onInput($event, letter)"
            @focus="focus = letter.decoded"
            @focusout="
              () => {
                focus = '';
              }
            "
          />
        </template>
      </template>
    </p>
    <div class="additional-box">
      <p class="feedback" v-if="existsWrongLetter">
        Leider ist Ihre Lösung teilweise inkorrekt. Die fehlerhaften Ersetzungen
        wurden
        <span style="background-color: pink; margin-inline: 5px"> rot </span>
        hinterlegt.
      </p>
    </div>
  </div>
</template>

<script>
// import store from "@/store";

import store from "@/store";

export default {
  data() {
    return {
      testAction: "Nix",
      focus: "",
      hover: "",
      text: {
        letters: {
          B: {
            encoded: "P",
            decoded: "B",
            replacement: "",
            isDecoded: false,
            hover: false,
          },
          C: {
            encoded: "A",
            decoded: "C",
            replacement: "C",
            isDecoded: true,
          },
          D: {
            encoded: "C",
            decoded: "D",
            replacement: "D",
            isDecoded: true,
          },
          E: {
            encoded: "F",
            decoded: "E",
            replacement: "",
            isDecoded: false,
            hover: false,
          },
          H: {
            encoded: "B",
            decoded: "H",
            replacement: "H",
            isDecoded: true,
          },
          I: {
            encoded: "Z",
            decoded: "I",
            replacement: "I",
            isDecoded: true,
          },
          K: {
            encoded: "Q",
            decoded: "K",
            replacement: "K",
            isDecoded: true,
          },
          M: {
            encoded: "R",
            decoded: "M",
            replacement: "M",
            isDecoded: true,
          },
          N: {
            encoded: "W",
            decoded: "N",
            replacement: "N",
            isDecoded: true,
          },
          O: {
            encoded: "U",
            decoded: "O",
            replacement: "O",
            isDecoded: true,
          },
          R: {
            encoded: "S",
            decoded: "R",
            replacement: "R",
            isDecoded: true,
          },
          S: {
            encoded: "I",
            decoded: "S",
            replacement: "",
            isDecoded: false,
            hover: false,
          },
          T: {
            encoded: "K",
            decoded: "T",
            replacement: "",
            isDecoded: false,
            hover: false,
          },
          U: {
            encoded: "O",
            decoded: "U",
            replacement: "",
            isDecoded: false,
            hover: false,
          },
          V: {
            encoded: "L",
            decoded: "V",
            replacement: "",
            isDecoded: false,
            hover: false,
          },
        },
        sentence: [
          "DER",
          "BOSS",
          "VERSTECKT",
          "SICH",
          "UNTER",
          "SEINEM",
          "BETT",
        ],
      },
    };
  },
  emits: ["isFinished"],
  methods: {
    backBtnClicked() {
      this.$emit("assignment1-back");
    },
    focusOn(letter) {
      document.getElementById(letter).focus();
    },
    forwardBtnClicked() {
      this.$emit("assignment1-finished");
    },
    onInput(e, letter) {
      // a v-on handler receives the native DOM event
      // as the argument.
      letter.replacement = e.target.value.toUpperCase();
      letter.replacement === letter.decoded
        ? (letter.isDecoded = true)
        : (letter.isDecoded = false);
      if (letter.replacement != "") {
        this.logAction({ changedLetter: letter }, this.text);
      }
      if (this.isFinished) {
        this.finishLogging();
      }
    },
    logAction(action, text) {
      const date = new Date();
      const actionObj = { date, action, text };
      this.testAction = actionObj;
      store.commit(
        "securityA1AppendAction",
        JSON.parse(JSON.stringify(actionObj))
      );
      //console.log(JSON.parse(JSON.stringify(actionObj))); //add action to assignment1 actions array in store (nothing will be posted to backend yet)
    },
    finishLogging() {
      //store.commit('securityA1LogPost'); //will be called when assignment2 is finished (will be called together with store.commit('securityA2LogPost'))--> see store.commit('securityTotalResultsPost')
    },
  },
  computed: {
    isFinished() {
      let fin = true;
      for (const letter in this.text.letters) {
        if (!this.text.letters[letter].isDecoded) {
          fin = false;
        }
      }
      if (fin == true) {
        this.$emit("isFinished");
      }
      return fin;
    },
    existsWrongLetter() {
      let wrongReplacement = false;
      for (const letter in this.text.letters) {
        if (
          this.text.letters[letter].replacement &&
          !this.text.letters[letter].isDecoded
        ) {
          wrongReplacement = true;
        }
      }
      return wrongReplacement;
    },
  },
};
</script>

<style scoped>
p.sentence {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
p.feedback {
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.word {
  display: flex;
  justify-content: center;
  margin: 10px;
}
.letter {
  display: flex;
  justify-content: center;
  font-size: 30px;
  min-width: 20px;
  padding: 3px;
  border: 2px solid transparent;
  font-family: monospace;
}

.letter.decoded {
  font-size: 30px;
  min-width: 20px;
  padding: 3px;
  border: 1px black;
  background-color: rgb(126, 183, 126);
  border-radius: 3px;
}

.letter.encoded {
  background-color: white;
  color: grey;
  min-width: 20px;
  padding: 3px;
  border: 0.5px solid black;
  box-shadow: 1px 1px 4px rgb(47, 43, 43);
  background-color: lightgray;
  border-radius: 2px;
}

.letter.encoded.replaced,
input.replaced {
  background-color: rgb(224, 172, 172);
  color: rgb(170, 4, 4);
}

.letter.encoded.letter-hover {
  font-weight: bold;
  border: 2px dotted black;
}

.letter.encoded.letter-focus {
  font-weight: bold;
  color: gray;
  border: 2px dotted black;
}

p.letter-forms {
  display: flex;
  min-width: 600px;
  justify-content: center;
  flex-wrap: wrap;
}
input.input-letter {
  display: flex;
  text-align: center;
  max-width: 40px;
  min-width: 20px;
  font-size: 20px;
  margin: 20px;
  border: 2px dotted black;
  border-radius: 10px;
  box-shadow: 4px 2px 5px rgb(47, 43, 43);
}
input[disabled] {
  color: black;
  background-color: rgb(126, 183, 126);
  border: 1.5px solid black;
}
.additional-box {
  width: 100%;
  padding: 10px;
  border: 1px solid #595959;
  box-sizing: border-box;
  min-height: 100px;
  margin-top: 40px;
}
</style>
