<template>
  <!-- 
    MAIN COMPONENT FOR SECURITY ASSIGNMENT 
    on start it shows the tutorial
    after the tutorial is finished or skipped it shows the assignment
    -->
  <div class="container" v-if="this.dataReady">
   
    <div
      style="
        color: red;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
      "
      v-if="false"
    >
      BUTTONS ARE FOR DEVELOPMENT <br />
      <button @click="startIntroduction">Intro</button>
      <button @click="startSimpleEncryption">Einfaches Verschlüsseln</button>
      <button @click="startSimpleEncryption2">Caesar-Verfahren</button>
      <button @click="startSimpleEncryption3">Aufgabe</button>
      <button @click="startAssignment1">Assign1</button>
      <button @click="startAddendumSymmetric">Addendum Symmetric</button>
      <button @click="startAsymmetricEncryption">Asymmetric Encryption</button>
      <button @click="startAssignment2Tutorial">Assign2 Tutorial</button>
      <button @click="startAssignment2">Assign2</button>
      <button @click="startPrettyGoodPrivacy">Pretty Good Privacy</button>
      <button @click="startSecurityConclusion">Conclusion</button>
    </div>

    <div class="error-message" v-if="!this.user">
      Um auf das Modul "Sortieren mit System" zugreifen zu können, müssen Sie
      angemeldet sein.
      <router-link to="/"
        ><div class="custom-btn">Zur Anmeldung</div></router-link
      >
    </div>
    <div class="error-message" v-else-if="this.isSecurityCompleted">
      Sie haben das Modul "Sortieren mit System" bereits abgeschlossen. Eine
      erneute Bearbeitung ist derzeit nicht möglich.
      <router-link to="/informatik"
        ><div class="custom-btn">Zum Informatik OSA</div></router-link
      >
    </div>
    <div v-else>
      <div class="introduction-container" v-if="showIntroduction">
        <SecurityIntro @security-intro-finished="startSimpleEncryption">
        </SecurityIntro>
      </div>

      <div class="simple-encryption-container" v-if="showSimpleEncryption">
        <SimpleEncryption
          @simple-encryption-finished="startSimpleEncryption2"
          @simple-encryption-back="startIntroduction"
        >
        </SimpleEncryption>
      </div>
      <div class="simple-encryption2-container" v-if="showSimpleEncryption2">
        <SimpleEncryption2
          @simple-encryption2-finished="startSimpleEncryption3"
          @simple-encryption2-back="startSimpleEncryption"
        >
        </SimpleEncryption2>
      </div>
      <div class="simple-encryption3-container" v-if="showSimpleEncryption3">
        <SimpleEncryption3
          @simple-encryption3-finished="startAssignment1"
          @simple-encryption3-back="startSimpleEncryption2"
        >
        </SimpleEncryption3>
      </div>
      <div v-if="showAssignment1">
        <Assignment1 
        @assignment1-back="startSimpleEncryption3"
        @assignment1-finished="startAddendumSymmetric">
        </Assignment1>
      </div>

      <div v-if="showAddendumSymmetric">
        <AddendumSymmetric
          @addendum-symmetric-back="startAssignment1"
          @addendum-symmetric-finished="startAsymmetricEncryption"
        >
        </AddendumSymmetric>
      </div>

      <div v-if="showAsymmetricEncryption">
        <AsymmetricEncryption
          @asymmetric-encryption-back="startAddendumSymmetric"
          @asymmetric-encryption-finished="startAssignment2Tutorial"
        >
        </AsymmetricEncryption>
      </div>

      <div v-if="showAssignment2Tutorial">
        <Assignment2Tutorial
          @assignment2-tutorial-back="startAsymmetricEncryption"
          @assignment2-tutorial-finished="startAssignment2"
        >
        </Assignment2Tutorial>
      </div>

      <div v-if="showAssignment2">
        <Assignment2
        @assignment2-back="startAssignment2Tutorial"
         @assignment2-finished="startPrettyGoodPrivacy">
        </Assignment2>
      </div>

      <div v-if="showPrettyGoodPrivacy">
        <PrettyGoodPrivacy
          @pgp-back="startAssignment2"
          @pgp-finished="startSecurityConclusion"
        >
        </PrettyGoodPrivacy>
      </div>

      <div v-if="showSecurityConclusion">
        <SecurityConclusion
          @security-conclusion-back="startPrettyGoodPrivacy"
          @security-conclusion-finished="finishSecurity"
        >
        </SecurityConclusion>
      </div>
    </div>
  </div>
</template>

<script>
import SecurityIntro from "@/components/inf/security/SecurityIntro.vue";
import SimpleEncryption from "@/components/inf/security/SimpleEncryption.vue";
import SimpleEncryption2 from "@/components/inf/security/SimpleEncryption2.vue";
import SimpleEncryption3 from "@/components/inf/security/SimpleEncryption3.vue";
import Assignment1 from "@/components/inf/security/Assignment1/Assignment1.vue";
import AddendumSymmetric from "@/components/inf/security/AddendumSymmetric.vue";
import AsymmetricEncryption from "@/components/inf/security/AsymmetricEncryption.vue";
import PrettyGoodPrivacy from "@/components/inf/security/PrettyGoodPrivacy.vue";
import Assignment2Tutorial from "@/components/inf/security/Assignment2/Assignment2Tutorial.vue";
import Assignment2 from "@/components/inf/security/Assignment2/Assignment2.vue";
import SecurityConclusion from "@/components/inf/security/SecurityConclusion.vue";

import * as myOSAhandler from "@/scripts/inf_my-osa-api-handler.js";


export default {
  components: {
    SecurityIntro,
    SimpleEncryption,
    SimpleEncryption2,
    SimpleEncryption3,
    Assignment1,
    AddendumSymmetric,
    AsymmetricEncryption,
    Assignment2Tutorial,
    Assignment2,
    PrettyGoodPrivacy,
    SecurityConclusion,
   
  },
  data() {
    return {
      dataReady: false,
      user: {},
      showIntroduction: true,
      showSimpleEncryption: false,
      showSimpleEncryption2: false,
      showSimpleEncryption3: false,
      showAssignment1: false,
      showAddendumSymmetric: false,
      showAsymmetricEncryption: false,
      showAssignment2Tutorial: false,
      showAssignment2: false,
      showPrettyGoodPrivacy: false,
      showSecurityConclusion: false,
    };
  },
  async mounted() {
    this.user = JSON.parse(window.localStorage.getItem("userData"));
    this.isSecurityCompleted = await this.getIsSecurityCompleted();
    this.$store.dispatch('setHeaderPageTitle', 'Online Self Assessment Informatik');
    this.$store.dispatch('setIsUserInAssignment', false); //user opened securityView --> user is in assignment
    this.dataReady = true;
  },
  unmounted: function () {
    this.$store.dispatch("setIsUserInAssignment", false); //user closed securityView --> user is not in assignment anymore
  },
  methods: {
    hideAllComponents() {
      this.showIntroduction = false;
      this.showSimpleEncryption = false;
      this.showSimpleEncryption2 = false;
      this.showSimpleEncryption3 = false;
      this.showAssignment1 = false;
      this.showAddendumSymmetric = false;
      this.showAsymmetricEncryption = false;
      this.showAssignment2Tutorial = false;
      this.showAssignment2 = false;
      this.showPrettyGoodPrivacy = false;
      this.showSecurityConclusion = false;
    },
    startIntroduction() {
      this.hideAllComponents();
      this.showIntroduction = true;
    },
    startSimpleEncryption() {
      this.hideAllComponents();
      this.showSimpleEncryption = true;
    },
    startSimpleEncryption2() {
      this.hideAllComponents();
      this.showSimpleEncryption2 = true;
    },
    startSimpleEncryption3() {
      this.hideAllComponents();
      this.showSimpleEncryption3 = true;
    },
    startAssignment1() {
      this.hideAllComponents();
      this.showAssignment1 = true;
    },
    startAddendumSymmetric() {
      this.hideAllComponents();
      this.showAddendumSymmetric = true;
    },
    startAsymmetricEncryption() {
      this.hideAllComponents();
      this.showAsymmetricEncryption = true;
    },
    startAssignment2Tutorial() {
      this.hideAllComponents();
      this.showAssignment2Tutorial = true;
    },
    startAssignment2() {
      this.hideAllComponents();
      this.showAssignment2 = true;
    },
    startPrettyGoodPrivacy() {
      this.hideAllComponents();
      this.showPrettyGoodPrivacy = true;
    },
    startSecurityConclusion() {
      this.hideAllComponents();
      this.showSecurityConclusion = true;
    },
    async finishSecurity() {
      //get my informatik OSA for current user
      const myOSA = await myOSAhandler.getMyInformatikOSA(
        this.$store.getters.getUser.username
      );
      //set securityFinished=true in backend
      await myOSAhandler.finishModule(myOSA.id, { securityFinished: true });
      this.$router.push("/informatik");
    },
    async getIsSecurityCompleted() {
      return false; //TODO remove
      //const myOSA = await myOSAhandler.getMyInformatikOSA(this.$store.getters.getUser.username);
      //return myOSA.securityFinished;
    },
  },
};
</script>

<style scoped></style>
