<template>
  <div class="container">
    <AssignmentbarSecurity
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    ></AssignmentbarSecurity>
    <!--<div class="animate glow row">
    <h1>Einfaches Verschlüsseln</h1>
    
      <p class="col-12">
        Einerseits wollen wir mit kryptographischen Verfahren unverschlüsselte
        Texte (sogenannte Klartexte) verschlüsseln, andererseits würden diese
        verschlüsselten Texte uns wenig Mehrwert in der Kommunikation bringen,
        wenn wir sie nicht wieder entschlüsseln könnten.
      </p>
      <p class="col-6" style="display: flex; align-items: center">
        In kryptographische Verfahren werden üblicherweise Anweisungen sowohl
        zur Verschlüsselung als auch Entschlüsselung in sogenannten Schlüsseln
        kodiert. Damit ein solches Verfahren sinnvoll angewendet werden kann,
        sollte jeder Schlüssel zur Verschlüsselung auch einen zugehörigen
        Schlüssel zur Entschlüsselung besitzen.
      </p>
      <img
        class="col-5"
        :src="symmetricEncryption"
        alt="Darstellung unverschlüsselte Kommunikation"
      />
    </div>-->
    <div class="animate glow row">
      <h2>Caesar-Verfahren</h2>
      <p class="col-12">
        Diese etwas abstrakte Darstellung wollen wir nun mit sogenannten 
        Ersetzungsverfahren konkreter machen. Bei Ersetzungschiffren erfolgt die 
        Verschlüsselung durch das Ersetzen von Zeichen durch andere Zeichen. Eine 
        einfache Methode, solche Ersetzungen zu finden, ist das Caesar-Verfahren, 
        welches jeden Buchstaben um eine bestimmte Anzahl von Schritten im 
        Alphabet verschiebt.
      </p>
      <div class="image-container">
  <img
    class="col-6"
    :src="replacement"
    alt="Darstellung unverschlüsselte Kommunikation"
  />
</div>
<a href="your-link" class="credit" target="_blank" rel="noopener noreferrer">Grafiken erstellt auf Canva</a>
      <p class="col-12" >
        In dieser Grafik sehen Sie ein Beispiel für eine solche Ersetzung
        mittels des Caesar-Verfahrens. Hier ist der Verschiebeschlüssel „3“
        gewählt worden, das heißt, dass jeder Buchstabe um drei Positionen
        verschoben wird. Daher ist die verschlüsselte Version von „DANKE“ die
        Zeichenfolge „GDQNH“.
      </p>
      <p>
        Jedoch muss die Ersetzung keine bestimmte Ordnung besitzen, sondern es
        können Zeichen beliebig durch andere Zeichen ersetzt werden. Damit wir
        den Text auch wieder entschlüsseln können, dürfen keine zwei
        unterschiedlichen Zeichen im Klartext durch das gleiche Zeichen ersetzt
        werden.
      </p>

      <p>
        Ein so verschlüsselter Text kann durch das Rückersetzen der Buchstaben 
        wieder entschlüsselt werden. Kryptographische Verfahren wie diese, in denen 
        wir von der Verschlüsselung auf die Entschlüsselung als auch umgekehrt von 
        der Entschlüsselung auf die Verschlüsselung schließen können, heißen 
        symmetrische Verfahren. Verfahren, für die dies nicht der Fall ist, heißen 
        asymmetrisch und werden etwas später im Modul besprochen. Zunächst 
        wollen wir aber Sicherheitsaspekte von Ersetzungsverfahren unter die Lupe 
        nehmen.
      </p>
    </div>
    <!--<div class="animate glow row">
      <h2>Ihre Aufgabe in diesem Modul</h2>
      <p>
        In den folgenden Aufgaben werden Sie die in diesem Modul vorgestellten
        Konzepte der Verschlüsselung anwenden. Beim Lösen der Aufgaben sollen
        Sie möglichst strukturiert und nach einem logisch nachvollziehbaren
        Verfahren vorgehen, auch wenn einfaches „Ausprobieren“ oft verlockend
        scheint. Überlegen Sie sich Ihre Vorgehensweise genau und versuchen Sie
        die Aufgaben mit so effizient wie möglich zu lösen!
      </p>
      <p>
        Im ersten Teil sollen Sie einen zuvor verschlüsselten Text mit möglichst
        wenigen Schritten zu entschlüsseln. Dabei werden Sie eine abgefangene
        Nachricht, die zuvor symmetrisch verschlüsselt wurde, wieder lesbar machen. Sie
        werden dabei vielleicht erkennen, dass diese Art der Verschlüsselung für
        das Übermitteln von sensiblen Nachrichten nicht ausreichend sicher ist.
        Hier schließt die zweite Aufgabe dieses Moduls an, in der Sie eine
        sichere Kommunikation mittels moderner Verschlüsselungsmethoden
        einrichten werden.
      </p>
    </div>-->

    <div class="custom-btn" @click="forwardBtnClicked">Weiter</div>
    <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
  </div>
</template>

<script>
import AssignmentbarSecurity from "@/components/inf/security/AssignmentbarSecurity.vue";

export default {
  name: "SimpleEncryption2",
  components: {
    AssignmentbarSecurity,
  },
  emits: ["simple-encryption2-back", "simple-encryption2-finished"],
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  data() {
    return {
      symmetricEncryption: require("@/assets/inf/security/symmetricEncryption.png"),
      replacement: require("@/assets/inf/security/replacement.png"),
    };
  },
  methods: {
    backBtnClicked() {
      this.$emit("simple-encryption2-back");
    },
    forwardBtnClicked() {
      this.$emit("simple-encryption2-finished");
    },
  },
};
</script>

<style scoped>
.credit {
  font-size: 10px;
  color: #555;
  text-align: center; /* Center the text within the link */
  margin-top: 10px; /* Add some space above the link */
  padding-right: 0; /* Reset this if it's not needed */
  width: 100%; /* Take the full width to center the text properly */
  padding-bottom: 10px;
}
.custom-btn,
.custom-btn-secondary {
  float: right;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
img{
  max-width: 100%;
  height:100%;
}
.image-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center the link underneath the image */
}
</style>
