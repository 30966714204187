<template>
  <div class="container">
    <AssignmentbarSecurity
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    ></AssignmentbarSecurity>
    <div class="row">
        <h1>Aufgabe: Das Versteck</h1>
      <div v-if="!finished">
      <p>Eine führende Figur des organisierten Verbrechens soll verhaftet werden. Er hat sich aber vor seiner Fahndung erfolgreich versteckt. Folgende verschlüsselte Nachricht zwischen zwei seiner Handlanger:innen wurde abgefangen. </p>
      <p>Die <span style="background-color: lightgreen;">grün</span> hinterlegten Buchstaben sind bereits korrekte Ersetzungen. Sie als Expert:in wurden nun gebeten, die restliche Nachricht zu entschlüsseln.</p>

      <p>Sie können annehmen, dass Großbuchstaben und Kleinbuchstaben durch den gleichen Buchstaben ersetzt wurden.</p>

      <p>Versuchen Sie, so strukturiert wie möglich vorzugehen!</p>
      </div>
      <p v-if="finished">Gratulation! Dank Ihnen konnte der Boss dingfest gemacht werden. Aber nach zwei Wochen unter seinem Bett sah er sowieso schon etwas hungrig aus ...</p>

      <p v-if="finished">Unten sehen Sie nochmal den vollständig verschlüsselten Text und den Klartext gegenübergestellt.</p>

          <EncodedSentence @isFinished = "() => {finished = true;}"></EncodedSentence>

    </div>

    <div class="btn-container-right">
          <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>
          <div v-if="finished" class="custom-btn" @click="forwardBtnClicked">Weiter</div>
    </div>

  </div>

</template>

<script>
import AssignmentbarSecurity from "@/components/inf/security/AssignmentbarSecurity.vue";
import EncodedSentence from "@/components/inf/security/Assignment1/EncodedSentence.vue";

export default {
  name: "SecurityAssignment1",
  components: {
    AssignmentbarSecurity,
    EncodedSentence,
  },
  emits: ["assignment1-back","assignment1-finished"],
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  data() {
    return {
      dummyAssign1: require("@/assets/inf/security/dummy_assign1.png"),
      finished: false,
      
    };
  },
  methods: {
    backBtnClicked() {
        this.$emit("assignment1-back");
    },
    forwardBtnClicked() {
        this.$emit("assignment1-finished");
    }
  },
};
</script>

<style scoped>
p{
  line-height: 1.3;
}

.btn-container-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  margin: 20px;
}
</style>
