<template>
  <div id="key-container">
    <div class="key">
      <svg
        viewBox="0 0 497.2 192.9"
      >
        <g>
          <g>
            <path d="M0,91.6L0,91.6L0,91.6L0,91.6z" />
            <g>
              <path
                class="key-icon"
                :class="color"
                d="M331.2,32.5c-14.5,13.8-23.4,28.7-26.7,46.8l-281.4-1c-10,1.1-17.8,8.5-17.8,17c1.1,9.6,8.9,17,17.8,17H81
				v53.2h33.4v-53.2h66.7v42.6h33.4v-42.6l90.1,1.1c4.4,17,12.2,33,26.7,46.8c36.7,35.1,96.8,35.1,133.5,0s36.7-92.6,0-127.7
				C428-2.6,367.9-2.6,331.2,32.5z"
              />
            </g>
          </g>
        </g>
        <text
          transform="matrix(1 0 0 1 359.8987 141.7886)"
          class="text unselectable"
        >
          {{ keyName}}
        </text>
      </svg>
    </div>
    <div class="error-message" style="display: flex; justify-content: center; font-family: 'Space Mono';">{{errorMessage}}</div>
  </div>
</template>

<script>
export default {
  name: "ArrowIcon",

  props: {
    color: String, //white or yellow
    keyName: String,
    errorMessage: String,
  },
};
</script>

<style scoped>
.key-icon {
  stroke: #231f20;
  stroke-width: 10;
  stroke-miterlimit: 10;
}
.white {
  fill: #ffffff;
}

.yellow {
  fill: #f8f49c;
}

.red {
  fill: #FF99AB;
}

.green{
  fill: #9AFFA0;
}

.gray {
  fill: #aaa9a9;
}

.invisible {
  visibility: hidden;
}

.text {
  font-size: 130px;
  stroke: none;
}
</style>
