<template>
    <div class="container">
    <AssignmentbarSecurity
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    />
    <div class="animate glow row">
      <h1>Mit Sicherheit</h1>
    
      <p>
        Heutzutage, in Zeiten von Home Office, Social Media und Co, haben wir
        als Gesellschaft viele Bereiche unseres alltäglichen
        Informationsaustauschs in das Internet verlagert. Doch diese „neue“ Art
        zu kommunizieren bringt nicht nur Erleichterung, sondern auch so
        einige neue Herausforderungen, von denen wir einige im folgenden
        Modul besprechen wollen.
      </p>
      </div>
      <div class="animate glow row">      <div  class="col-6">
      <p>
        Einerseits werden im Internet nicht nur Katzenfotos geteilt, sondern auch 
        Daten, die wir privat halten wollen. Viele Arten solcher Daten können von 
        Dritten in einer für uns schädlichen Weise verwendet werden, wie es z. B. beim 
        Kreditkartenbetrug oder beim Identitätsbetrug im Internet oft der Fall ist.
      </p>      <p>
        Andererseits gibt es noch eine große weitere Herausforderung für sichere
        Kommunikation im Internet. Nämlich mit ausreichender Sicherheit zu
        entscheiden, ob eine Nachricht authentisch ist, also ob eine Nachricht
        in der uns vorliegenden Weise von der von uns erwarteten Verfasser:in
        verschickt wurde.
      </p>
    <p>In dieser Grafik sehen Sie eine Darstellung einer unverschlüsselten 
       Kommunikation. Bei dieser können Dritte sehr einfach alle über den 
       Kommunikationskanal gesendeten Nachrichten, abfangen und in Klartext 
       auslesen. Dabei können auch sensible Daten wie Passwörter und geheime 
       Daten (z. B. Bankdaten) in ungewollte Hände gelangen.
    </p>
    </div>
      <img class="col-6"
        :src="unencryptedCommunication"
        alt="Darstellung unverschlüsselte Kommunikation"
      />

    </div>
    <div class = "animate glow row">
      <p>
        Mit diesen und weiteren Problemen und möglichen Lösungen mittels 
        Verschlüsselung beschäftigt sich die Kryptographie, ein Teilbereich der 
        Informatik und Mathematik. Wie solche Verschlüsselungsmethoden 
        funktionieren, werden Sie in diesem Modul kennen lernen.
      </p>
    </div>

    <div class="custom-btn" @click="endIntroduction()">Weiter</div>
  </div>
</template>

<script>
import AssignmentbarSecurity from "@/components/inf/security/AssignmentbarSecurity.vue";

export default {
  name: "SecurityIntroduction",
  components: {
    AssignmentbarSecurity,
  },
  emits: ["security-intro-finished", "start-assignment2-shortcut"],
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  data() {
    return {
      unencryptedCommunication: require("@/assets/inf/security/unencryptedCommunication.jpg"),
    };
  },
  methods: {
    endIntroduction() {
      this.$emit("security-intro-finished");
    },
    /** vue-dndrop library methods 
    onDrop(dropResult) {
      console.log(dropResult);
      this.items = applyDrag(this.items, dropResult);
    },*/
  },
};
</script>

<style scoped>
.custom-btn,
.custom-btn-secondary {
  float: right;
}
.draggable-item-horizontal {
  border: 2px solid black;
  border-radius: 20px;
  background: lightgray;
  width: 40px;
  height: 40px;
  margin: 50px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
img {
  max-width: 100%;
  height:100%;
 
  
}
</style>
