<template>
  <div class="container mb-2 pb-2">
    <div class="button-bar assignment-bar">
      <!-- Button trigger modal -->
      <div class="button-bar-left">
        <div
          v-if="showHelpBtn"
          type="button"
          @click="openHelpModal"
          class="custom-btn-secondary dismiss-btn"
          data-bs-toggle="modal"
          data-bs-target="#modal"
        >
          <i class="bi bi-question-circle" style="padding-right: 5px;"></i>Hilfe
        </div>
      </div>
      <div class="button-bar-right">
        <div
          v-if="showDismissBtn"
          type="button"
          @click="openQuitModal"
          class="custom-btn-secondary dismiss-btn"
          data-bs-toggle="modal"
          data-bs-target="#modal"
        >
          „Mit Sicherheit“ abbrechen
        </div>
      </div>
    </div>

    <BaseModal>
      <template v-slot:header>
        <div class="modal-title">
          <!-- help modal title -->
          <div v-if="showModal === 'help'">Anleitung</div>
          <!-- quit modal title -->
          <div v-else-if="showModal === 'quit'">„Mit Sicherheit“ abbrechen?</div>
          <div v-else>Error</div>
        </div>
      </template>

      <template v-slot:body>
        <!-- help modal body -->
        <div v-if="showModal === 'help'">
          <p><strong>A</strong>nela, <strong>B</strong>eri, <strong>C</strong>em und <strong>D</strong>ora sind Freund:innen, haben heiße News miteinander zu teilen. So heiß, dass nicht alle vier Freund:innen jede Neuigkeit kennen soll. Um dabei auf Nummer sicher zu gehen, wollen sie ihre Kommunikation asymmetrisch verschlüsseln. </p>
          <p>Jede der vier Personen besitzt ihren zugehörigen privaten Schlüssel bereits. Es werden also nur öffentliche Schlüssel verteilt. Der zu jeder Person zugehörige öffentliche Schlüssel ist der Schlüssel mit dem gleichen Anfangsbuchstaben wie der Name der Person.</p>
          <p>Im Folgenden werden Ihnen der Reihe nach eine Menge an Anforderungen präsentiert. Sie sollen eine Schlüsselverteilung finden, die jeder dieser Anforderungen erfüllt. </p>
          <p>Wenn Sie eine Aufgabe korrekt gelöst haben, können Sie mit „Überprüfen” zur nächsten Teilaufgabe übergehen. Bei fehlerhaften Versuchen bekommen Sie entsprechendes Feedback zu Ihrem Lösungsversuch.</p>

        </div>
        <!-- quit modal body -->
        <div v-else-if="showModal === 'quit'">
          <p>
            Wollen Sie das Modul „Mit Sicherheit“ wirklich abbrechen? Bitte beachten Sie, dass Ihre bisherigen Fortschritte nicht gespeichert werden und 
            Sie keine Rückmeldung zu Ihrer aktuellen Bearbeitung der Aufgaben bekommen, wenn Sie jetzt abbrechen.
                 </p>
          <p>
            Falls Sie dennoch abbrechen möchten, teilen Sie uns bitte Ihren
            Grund mit:
          </p>
          <form>
            <div class="form-group">
              <textarea class="form-control" id="message-text"></textarea>
            </div>
          </form>
        </div>
        <div v-else>Error loading text</div>
      </template>

      <template v-slot:footer>
        <div v-if="showModal === 'help'">
          <button type="button" class="btn custom-btn" data-bs-dismiss="modal">
            OK
          </button>
        </div>
        <!-- quit modal -->
        
        <div v-else-if="showModal === 'quit'">
          <button type="button" class="btn custom-btn-secondary" data-bs-dismiss="modal">
            Nein, Modul fortsetzen
          </button>
          <button
            type="button"
            class="btn custom-btn-danger"
            data-bs-dismiss="modal"
            @click="endAssignment"
          >
          Ja, Modul abbrechen
          </button>
        </div>
        <div v-else>
          <button type="button" class="btn custom-btn" data-bs-dismiss="modal">
            Schließen
          </button>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";

export default {
  name: "AssignmentbarSorting1",
  components: {
    BaseModal,
  },
  data() {
    return {
      showModal: "",
    };
  },
  props: {
    showHelpBtn: Boolean,
    showDismissBtn: Boolean,
  },
  emits: ["end-assignment", "skip-tutorial"],
  methods: {
    openHelpModal() {
      this.showModal = "help";
    },
    openQuitModal() {
      this.showModal = "quit";
    },
    endAssignment() {
      let messageText = document.getElementById("message-text").value;
      if (messageText.length > 1) {
        this.saveQuitMessage(messageText);
      }

      this.$emit("end-assignment");
      this.$router.push("/informatik");
    },
    async saveQuitMessage(messageText) {
      try {
        await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
            "/api/inf-security-quit-messages",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
            body: JSON.stringify({
              data: {
                message: messageText,
              },
            }),
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>

<style scoped>
</style>
